.desktop-predictor-bar{
  display: flex ;
}
.mobile-predictor-bar{
  display: none !important;
}
.desktop_header{
  display:block;
}
.predicted_display_header{
  font-size: 1.75rem;
}
.info_header_box {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #f47524 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}
.nav {
  display: flex
;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}
.mobile-cards{
  display: none;
}
.mobile_header{
  display: none;
}
.predicted_rank_row{
  display: none;
}
.mid_info_display_desktop{
  display:flex;
}
.mid_info_display_mobile{
  display: none;
}
.change_button {
  /* width: 100%; */
  width: 25%;
  margin-top: 8px;
  font-size: 14px;
  padding: 10px;
  background-color: #F47523;
  border-radius: 5px;
  color: white;
  border: none;
}


.predictor_year_switch{
  width: 143px !important;
  border-radius:20px ;
}
@media (max-width:1600px) {
  .predictor_year_switch{
      width: 143.3px !important;
      border-radius:20px ;
  }
}
@media (max-width:1366px) {
  .predictor_year_switch{
      width: 108.3px !important;
      border-radius:20px ;
  }
}
.predictor-disclaimer{
  padding: 0% 5%;
    }
    .predictor_cards_mobile{
      display: none !important;
    }
/* Mobile View (up to 768px width) */
@media only screen and (max-width: 580px) {
  .predictor_cards_mobile{
    display: block !important;
  }
  .predictor_bg_blue_color{
    background-color: white !important;
  }
  .predictor-disclaimer{
margin: 0% 5%;
  }
  .predictor_year_switch{
    width: 143px !important;
    border-radius:20px ;
  }
  .mobile-predictor-bar{
    display: flex !important;
  }
  .desktop-predictor-bar{
    display: none !important;
  }
  .predicted_display_header p{
    font-size: 12px;
  }
  .mid_info_display_desktop{
    display:none !important;
  }
  .mid_info_display_mobile{
    display: block;
  }
  .college_card{
    display: none;
  }
  .mobile-cards{
    display: block;
    padding: 4%;
  }
    /* Main wrapper padding for mobile */
    .predicted_college_main_div {
      padding: 10px;
      background-color: #fce6da;
    }
    .desktop_header{
      display:none;
    }
    /* Stack info header vertically for mobile */
    .info_header_box {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
  
    /* Rank and user info box adjustments */
    .info_header_box h4 {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  
    /* Full width Change button for mobile */
    .change_button {
      /* width: 100%; */
      width: 70%;
      margin-top: 8px;
      font-size: 14px;
      /* padding: 10px; */
      background-color: #F47523;
      border-radius: 5px;
      color: white;
      border: none;
    }
  
    /* Disclaimer box adjustments */
    .alert {
      font-size: 12px;
      padding: 10px;
      margin-bottom: 15px;
      background: #E8F0EE;
      border: 2px solid #E8F0EE;
    }
  
    /* Each predicted college card */
    .college_card {
      background-color: white;
      border-radius: 8px;
      margin-bottom: 15px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      display: none !important;
    }
  
    /* Adjust card layout for mobile */
    .college_card .row {
      flex-direction: column;
      align-items: center;
    }
  
    /* Reduce logo size inside the card */
    .college_card img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
  
    /* College name */
    .college_card .col-md-12 {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    /* College details like rank, fees, etc. */
    .college_card .row div {
      font-size: 12px;
      text-align: center;
      margin-bottom: 5px;
    }
  
    /* Full width for Download button on mobile */
    .btn {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      background-color: #F47523;
      color: white;
      border: none;
      border-radius: 5px;
      text-align: center;
    }
    .mobile_header{
      display: block;
      box-shadow: 1px 2px 10.1px -3px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
  
 
  