.background_image_predictor_side{
    background-image: url('../../assets/predictor_background_image_side.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
   
    /* height:400px; */
    /* opacity: 0.2; */
    /* transform: rotate(-25deg) !important; */
}
.login-header{
    font-size: 50px !important;
    font-weight: bold;
}
.predictor_cards{
    padding: 2% 0;
}
.login-content-img{
    width: 10%;
    padding-top: 2%;
    text-align: center;
}
.login-content-desc {
    width: 90%;
    text-align: left;
    font-size: larger;
    padding-top: 2%;
    padding-right:2% ;
    font-weight: bold;
}
  
.register_container {
    /* background: linear-gradient(to bottom right, #44a1a0, #172a3a) !important; */
   /* background: #233974; */
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.register_container h3 {
    color: #ffff;
    font-weight: 600;
}
.register_container [type="radio"] {
    opacity: 0;
    height: 0px;
}
.register_container [type="radio"] + img {
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.6;
    box-shadow: none;
    border: 5px solid transparent;
    transition: box-shadow 1s, border 1s, opacity 1s;
}
.register_container [type="radio"]:checked + img {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
    border: 5px solid whitesmoke;
    opacity: 1;
}
.register_container .terms_check a {
    color: whitesmoke;
}
.register_container .terms_check a:hover {
    color: #2ec4b6;
}
.register_details {
    color: #ffff;
}
.register_container .form-control,
.register_container .form-control:focus {
    background: transparent;
    /* color: white; */
    outline: none;
    border: none;
    /* border: 0.1px solid whitesmoke; */
    border:1.5px solid #000000b3 !important;
    border-radius: 10px ;
    /* box-shadow: none; */
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
    /* border-radius: 0px; */
}
/* .register_container .form-control::placeholder {
    color: whitesmoke;
} */
.register_container .input-group-text {
    background: #172a3a;
    width: 40px;
    justify-content: center;
    color: #ffff;
    border-radius: 0px;
}
.register_button{
    width: 50%;
}
.register_heading_text{
    font-size: 3.5rem;
}
 .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none !important;
}
@media (max-width: 600px) {
.register_gender{
    /* display: flex; */
    /* margin-left:2%; */
}
.predictor_register_form_div{
    padding: 0 10% !important;
}


.register_button{
    width: 100%;
}
.second_input{
    margin-top: 15px;
}
.register_heading_text{
    font-size: 2rem;
}
}