/* .background_image_predictor_side{
  background-image: url('../../assets/predictor_background_image_side.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
 
} */

.vertical-line {
  position: absolute;
  left: 4%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #dee2e6;
  z-index: 0;
}

.step-icon {
  width: 24px;
  height: 24px;
  z-index: 1;
}

.check-icon-wrapper {
  background-color:#28a745;
  border: 1px solid white; /* Bootstrap green */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.predictor_course_level_switch{
  width:111px !important;
  border-radius:20px;
  margin:0 auto;
  
}
.predictor_page{
  /* height: 100vh !important; Ensure full height */
  overflow-y: auto !important; /* Enable vertical scrolling */
  overflow-x: hidden !important;
}
.predictor-container {
    height: 100vh;
    background-color: #fae9e5; /* Light pink background as in the image */
  }
  .border_radius{
    border-radius: 10px !important;
    border:1px solid black !important;
  }
  .predictor-card {
    border-radius: 20px !important;
    padding: 30px 0px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-right:10% ;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #233974; /* Dark blue color for the header */
  }
  
  input, select {
    font-size: 14px;
  }
  
  img {
    max-width: 100%;
    border-radius: 15px;
  }
  .predictor_submit_button{
    width:50% !important;
  }
  .predictor_lable{
    color:#1d3d79;
    font-size:13px;
  
  }
  .predictor_heading{
    margin-bottom: 7% !important;
  }
  .shadow{
    box-shadow: 0px -1px 21px -8px rgba(0, 0, 0, 0.25) !important;

  }
  .predictor-benfits-display{
    padding-left: 5% !important;
  }
  @media screen and (min-width: 1366px) and (max-width:1600px)  {
    .vertical-line {
      left: 5.5% !important;
      bottom: 3% !important;
    }
  }
  @media only screen and (max-width: 580px) {
    .vertical-line {
      left: 10%;
      bottom: 10%;
    }
    .predictor-card{
      padding:0px;
      margin-right:0% !important ;
    
    
    }
    .predictor_second_div{
      margin-bottom:20%;
      padding: 0% 10% !important;
    }
    .predictor-benfits-display{
      padding:0 7% !important;
    }
    .predictor_doctor_image_position{
      display: none !important;
      
    }
    .predictor_submit_button{
      width:60% !important;
    }
    
    .predictor_heading{
      font-size: 20px;
      font-weight: bold !important;
      margin-top :5%;
    }
  }