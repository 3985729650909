.upgrade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f6f8fc;
}
small{
    font-size: 20px !important;
    font-weight: bold !important;
}
.list-unstyled{
    list-style-image:url('../../assets/predictor_background_image_side.png');;
}
.upgrade-content {
    text-align: center;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
}
.predictor_main_payment_page{
    padding: 0 5% !important;
}
.upgrade-content p {
    font-size: 18px;
    color: #012269;
}

.upgrade-content strong {
    font-weight: bold;
}

.offer-box {
    background-color: #ffeae3;
    padding: 30px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.discount {
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin-bottom: 10px;
}
.discount-left{
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin: 10% 0;
}
.limited-offer {
    font-size: 30px;
    color: #7d7d7d;
    margin-bottom: 20px;
}

.price {
    font-size: 42px;
    font-weight: bold;
    color: #012269;
}

.current-price {
    font-size: 42px;
    color: #012269;
}

.original-price {
    text-decoration: line-through;
    font-size: 18px;
    color: #999;
    margin-left: 10px;
}

.tax-info {
    font-size: 14px;
    color: #7d7d7d;
    margin: 10px 0;
}

.upgrade-btn {
    background-color: #f89a27;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.upgrade-btn:hover {
    background-color: #d87f1d;
}
.modal-content{
    border-radius: 15px !important;
}
.payment_background_color{
    background-color: #233974 !important;
}
.header-title{
    color:white !important
}
.border-mobile{
    border-radius: 10px !important;
    padding: 3% 3%;
}
.payment_container_padding{
    background-color: #233974;
}
.text-orange {
    color: #f26522;
  }
  
  .btn-orange {
    background-color: #f26522;
    color: white;
    border-radius: 5px;
    border: none;
  }
  
  .btn-orange:hover {
    background-color: #e0561d;
  }

@media (max-width: 600px) {
    .discount {
        font-size: 50px !important;
        color: #012269;
        margin-bottom: 10px;
    }
    .discount-left{
        font-size: 50px !important;
        font-weight: bold;
        color: #012269;
        margin-bottom: 10%;
    }
    .limited-offer {
        font-size: 30px;
        color: #7d7d7d;
        margin-bottom: 0px;
    }
    .offer-box {
        padding: 20px;
    }
    .container{
        background-color: #233974;
        padding-bottom: 5% !important;
    }
    .border-mobile-top{
        border-top:1px solid darkgray;
    }
    .header-title{
        color:#233974 !important;
        font-size:14px;
        padding: 0 3%;
        justify-content:safe;
    }
    .payment_background_color{
        background-color: #fff !important;
        
    }
    .payment_container_padding{
        padding: 0% 5% !important;
    }
    .border-mobile{
        border: 1px solid #233974;
        margin:0px 5% 0px 5% !important;
        
        /* padding: 5% 5%; */
    }
    .discount {
        font-size: 28px;
    }

    .price {
        font-size: 32px;
    }

    .current-price {
        font-size: 32px;
    }

    .original-price {
        font-size: 16px;
    }
}
