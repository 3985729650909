.background_image_predictor_side{
    background-image: url('../../assets/predictor_background_image_side.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
   
    /* height:400px; */
    /* opacity: 0.2; */
    /* transform: rotate(-25deg) !important; */
}
.login-header{
    font-size: 50px !important;
    font-weight: bold;
}
.predictor_cards{
    padding: 2% 0;
}
.login-content-img{
    width: 10%;
    padding-top: 2%;
}
.login-content-desc {
    width: 90%;
    text-align: left;
    font-size: larger;
    padding-top: 2%;
    padding-right:2% ;
    font-weight: bold;
}
.logo_predictor{
    width:183px;
    height:181px;
}
.doctors_group{
    width: 836px;
    height:547px;
}
.college_benefits {
    list-style: none; /* Remove default bullets */
    padding-left: 40px; /* Adjust space for the image */
}
.background{
    background:#FFEEE6;
}
.rounded-border{
    border-radius: 15px;
    border:1px solid black;
}
.predictor_cards{
    box-shadow: 0px -1px 21px -8px rgba(0, 0, 0, 0.25);
    

}
.college_benefits li {
    background-image: url('../../assets/predictor_list.png');
    background-repeat: no-repeat;
    background-position: 0 1px; /* Adjust the position of the image */
    background-size: 20px 20px; /* Set the size of the image */
    padding-left: 30px; /* Space between the image and the text */
    margin-bottom: 10px; /* Space between list items */
    color:white;
    font-weight: bold;
}
.blue-color{
    color:#233974 !important;
}
.border-color{
    border:1.5px solid #000000b3 !important;
}
.borderradius{
    border-radius: 10px !important;
}
.bg-orange{
    background-color:#233974;
}
.logo_predictor{
    margin-left: 5%;
}
.college_benefits{
    margin-left: 4%;
}
.heading-info{
    margin-left:6%;
}
.logo_predictor_mobile{
    display: none;
}
@media (max-width: 600px) {
    .login-header{
        font-size: 25px !important;
        font-weight: bold;
    }
    .login-content-desc{
        width: 85% !important;
        padding-left: 10px;
        text-align: left;
        font-size: 12px;
        
    }
    .predictor_login_form{
        padding: 0 10% !important;
    }
    .logo_predictor_mobile{
        display: block;
    }
    .college_benefits{
        display: none;
    }
    .doctors_group{
        display: none;
    }
    .logo_predictor{
        display: none;
        
    }
    .college_benefits{
        margin-left: 1%;
    }
    .heading-info{
        /* margin-left:1%; */
        display: none;
    }
    
}