@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&family=Roboto:wght@100;400&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.dmopMx
{
    display: none !important;
}
#main_container {
    font-family: "Poppins", sans-serif;
    min-height: 100vh !important;
    overflow-x: hidden;
}
.page_header {
    color: #233f78;
    font-weight: 400;
}
.small_label {
    color: #233f78;
    font-size: 13px;
    font-weight: 600;
}
.xs_label {
    color: #233f78;
    font-size: 12px;
    font-weight: 600;
}
input {
    color: #233f78;
}
.form-control {
    color: #233f78;
}
.header_text {
    color: #233f78;
    font-size: 12vh;
    margin-top: 40vh;
}
.text-orange {
    color: #f37421;
}
.text-blue {
    color: #233f78 !important;
}
.bg-orange {
    background-color: #f37421;
}
.bg-blue {
    background-color: #233f78;
}

.bg-indigo {
    background: #4b0082;
}
.text-indigo {
    color: #4b0082;
}
.api_loader_container,
.loader_container {
    position: fixed;
    z-index: 99999;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.4);
}
.loader_image {
    margin-top: 30vh;
}

@media (max-width: 768px) {
    .header_text {
        color: #233f78;
        font-size: 8vh;
        margin-top: 40vh;
    }
}

/* todo: delete file if not needed */
.message_error_container {
    position: fixed;
    bottom: 4vh;
    right: 10px;
    left: 10px;
    padding: 4px;
    z-index: 2;
    text-align: center;
}
.message_error_container span {
    color: #ffff;
    padding: 10px 20px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border: 1px dotted white;
}
.message_text {
    background-color: #44a1a0;
}
.error_text {
    background-color: #b23a48;
}
.min_height {
    min-height: 66vh !important;
}
.login_modal_container {
    /* align-items: center; */
    justify-content: center;
    background: transparent;
}
.login_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin: 10px;
}
.login_container {
    position: fixed !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 12;
    width: auto;
    text-align: center;
    padding: 20px;
    color: #ffff;
    min-width: 300px;
    box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.8);
    /* border-left: 1px solid #ffff; */
    background: linear-gradient(to bottom, #172a3a, #233f78) !important;
}
.login_container h6 {
    color: #ffff;
}
.login_modal_container .form-control,
.login_modal_container .form-control:focus {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border: 0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 300px !important;
}
.login_modal_container .form-control::placeholder {
    color: whitesmoke;
}
.login_modal_container .input-group-text {
    background: #172a3a;
    color: #ffff;
    border-radius: 0px;
    width: 40px;
    justify-content: center;
}

/* Side callback container */
.main_callback_container {
    align-items: center;
    justify-content: center;
    background: transparent;
}
.callback_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin: 10px;
}
.callback_container {
    position: fixed !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    width: auto;
    text-align: center;
    /* padding:20px; */
    color: #ffff;
    min-width: 300px;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.8);
    /* border-left: 1px solid #ffff; */
    background: linear-gradient(to bottom, #44a1a0, #172a3a) !important;
}
.callback_container h6 {
    color: #ffff;
}
.main_callback_container .form-control,
.main_callback_container .form-control:focus {
    color: #172a3a;
    outline: none;
    border: none;
    border: 0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 300px !important;
}

input:-webkit-autofill,
input:-webkit-autofill-strong-password {
    background-color: white !important;
}

.menu_close_btn {
    /* float: right; */
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer !important;
}
.login_content_section {
    background: transparent;
    color: white;
    border-right: 1px solid #44a1a0;
}
.login_btn {
    background-color: #233f78;
    color: #ffff !important;
    border: 1px solid white;
    padding: 6px 20px;
    box-shadow: none;
    font-size: 14px;
    transition: background-color 1s, color 1s, box-shadow 1s;
}
.login_btn:hover {
    background-color: #f37421;
    box-shadow: 2px 2px 4px black;
}
.abroad_btn {
    padding: 5px 15px;
    color: #ffff;
    border: none;
    box-shadow: none;
    background-color: #172a3a;
    transition: background-color 1s, box-shadow 1s, color 1s, border 1s;
}
.abroad_btn:hover,
.abroad_btn:focus {
    background-color: #44a1a0;
    outline: none !important;
    /* box-shadow: 2px 2px 4px black; */
}
.abroad_btn.user_link {
    width: 90%;
    margin: 10px auto;
    display: block;
    padding: 8px;
    border: 1px dotted #ffff;
    transition: background-color 1s;
}
.abroad_btn.user_link:hover,
.abroad_btn.user_link_active {
    color: #ffff;
    background-color: #44a1a0;
}
.breadcrumb {
    background: transparent !important;
    padding: 2px 5px !important;
}
.breadcrumb-item a {
    color: #233f78 !important;
}
.breadcrumb-item a:hover,
.breadcrumb-item {
    font-size: 13px !important;
    color: #f37421 !important;
}
.breadcrumb > li + li:before {
    content: "\3E" !important;
}
.up_arrow {
    position: fixed;
    border: 2px solid #ffff;
    bottom: 30px;
    right: 30px;
    color: #ffff;
    border-radius: 50%;
    background: #44a1a0;
    padding: 10px;
    z-index: 5;
}
.main_header {
    font-size: 36px;
    color: #f37421;
    margin-bottom: 5px;
    font-family: "Alegreya Sans SC", sans-serif;
}
.sub_header {
    font-size: 18px;
    color: #233f78;
}
.hori_line {
    height: 2px;
    margin-top: 0px;
    width: 100px;
    background: linear-gradient(to left, #172a3a, #44a1a0);
}
.popular_section {
    background: white;
}
.popular_section .card {
    box-shadow: 4px 4px 10px 2px lightgrey;
    color: #ffff;
    transition: margin-top 1s, box-shadow 1s;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.popular_section .card:hover {
    margin-top: -10px;
    box-shadow: 6px 6px 10px 2px lightgrey;
}
.fav_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    padding: 8px;
    z-index: 5;
    font-size: 22px !important;
    cursor: pointer;
    border: 2px solid#fff;
    background: rgba(0, 0, 0, 0.1);
    color: rgb(255, 0, 0.8);
}
.col_details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 5;
    text-align: left;
    font-weight: 600;
}
.popular_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: #f37421;
    border-radius: 20px;
    border: 2px solid #ffff;
    padding: 5px 10px;
    color: #ffff;
}
.interested_container {
    background: linear-gradient(to right, #44a1a0, #172a3a);
    color: #ffff;
}
.interested_container .abroad_btn {
    border: 1px dotted white;
}
.interested_container .main_header {
    color: #ffff;
}
.bg_overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.8);
    /* background: linear-gradient(to right,  #f37421, #f37421, #233f78, #233f78);  */
    opacity: 0.2;
}
.card .bg_overlay {
    opacity: 0.7;
}
.check_label {
    margin-left: 5px;
    color: #233f78;
    font-size: 14px;
}
/* todo: delete file if not needed */
.filter_header {
    padding: 10px;
    border: none;
    color: #f37421;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}
.filters_card input {
    cursor: pointer;
}
.filter_sub_header {
    padding: 6px;
    font-size: 14px;
    color: #f37421;
    text-transform: uppercase;
    border-bottom: 1px solid lightgray;
}
.filter_selector {
    padding: 6px;
    border: 1px solid lightgrey;
    /* box-shadow: 0px 2px 4px lightgray; */
    border-radius: 5px;
    margin-bottom: 10px;
}
.filter_header i {
    float: right;
    padding-top: 5px;
    font-size: 14px;
}
.clear_btn {
    border: 1px solid #b23a48;
    color: #b23a48;
    padding: 4px 8px;
    font-size: 13px;
    background-color: transparent;
    transition: background-color 1s, color 0.5s, border 0.5s;
}
.clear_btn:hover {
    border: 1px solid #9e0031;
    background-color: #9e0031;
    color: #ffff;
}
.filter_div {
    padding: 6px;
    text-align: left;
    border-bottom: 1px solid lightgray;
}
.filters_card {
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 6px lightgray;
    /* position: sticky;
    top:10vh; */
}
.filter_div select::before,
.filter_div select.form-control {
    border-radius: 0px !important;
    color: #233f78;
}
.filter_div .form-control {
    height: 40px;
    color: #233f78;
}
img.filter_loader {
    margin-top: 6vh;
    min-height: 300px;
}
.no_results {
    color: #f37421;
}

.filter_div input.form-control,
.filter_div input.form-control:focus {
    background: transparent !important;
    color: #233f78 !important;
    border: 1px solid lightgray;
    border-radius: 20px !important;
    outline: #233f78 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 30px;
    transition: box-shadow 1s;
}
.filter_div input.form-control:hover,
.filter_div input.form-control:focus {
    box-shadow: 0px 0px 6px 1px lightgray;
}
.filter_div .input-group > .custom-select:not(:last-child),
.filter_div .input-group > .form-control:not(:last-child) {
    border-radius: 20px !important;
}
.filter_div .input-group-text {
    position: absolute;
    top: 4px;
    left: 3px;
    bottom: 4px;
    z-index: 4;
    background: transparent;
    border: none;
    color: #233f78;
}
.popular_div,
.mci_div,
.fmge_div {
    padding: 3px 6px;
    font-size: 13px;
    margin: 5px;
    font-weight: 500;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.8);
}
.popular_div {
    background: #233f78;
}
.mci_div {
    background: #f37421;
}
.fmge_div {
    background: #17a2b8;
}

.autocomplete_card {
    position: absolute;
    top: 40px;
    padding: 10px;
    background: #ffff;
    color: #233f78;
}
ul.pagination {
    float: right;
}
ul.pagination li {
    padding: 8px 13px;
    background: #ffff;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
ul.pagination a {
    color: #233f78;
}
ul.pagination li.active {
    background: #233f78;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.6);
}
ul.pagination li.active a {
    color: #ffff !important;
}
ul.pagination li.disabled {
    opacity: 0.5;
}
.small_text {
    font-size: 12px;
    font-weight: 400;
}
.medium_text {
    font-size: 14px;
    white-space: pre-wrap;
}
.date_text {
    font-size: 14px;
    color: #f37421;
}
label.small_text {
    margin-bottom: 3px;
}
.text_blue {
    color: #233f78 !important;
}
.bg-black {
    background-color: #172a3a !important;
}
.cart_icon {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 10;
    height: 60px;
    width: 60px;
    background-color: #233f78;
    border: 1px solid white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 0px 6px grey;
}
.cart_icon i {
    font-size: 30px;
    color: #ffff;
}
.cart_counter {
    position: absolute;
    top: -5px;
    left: -5px;
    height: 20px;
    width: 20px;
    background: #fff;
    color: #233f78;
    border-radius: 50%;
    border: 1px solid #f37421;
}

@media screen and (max-width: 768px) {
}

.country_link
{
    margin-top: 2% important;
}
#home_navbar {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    padding: 2px 20px;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index:10;
    box-shadow: 0px 2px 4px lightgrey;
}
#main_header_navbar ul {
    padding: 10px !important;  
}
#main_header_navbar .nav-link {
    /* color: #233f78; */
    color:black;    
    transition: color 0.5s;
    cursor: pointer;
}
#main_header_navbar .nav-link:hover, .link_active {
    color:#f37421!important;
}
#home_navbar .form-inline .input-group {
    border-radius:20px;
}
.header_search input.form-control, .header_search input.form-control:focus {
    background: transparent !important;
    color:#44a1a0 !important;
    border:1px solid lightgray;
    border-radius: 20px !important;
    outline:#44a1a0 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 35px;
    transition: box-shadow 1s;
}
.header_search input.form-control:hover, .header_search input.form-control:focus {
    box-shadow: 0px 2px 4px lightgray;
}
.header_search .input-group>.custom-select:not(:last-child), .header_search .input-group>.form-control:not(:last-child) {
    border-radius: 30px !important;
}
.header_search .input-group-text {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index:4;
    background: transparent;
    border: none;
    color: #233f78;
    justify-content: center;
}

.header_search input::placeholder {
    color: darkgrey;
}

.states_dropdown_content {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    background-color: #ffff;
    padding: 20px;
    z-index: 10;
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 2px 10px lightgray;
}
.states_dropdown_content .state_link {
    padding: 0px;
    margin: 2px;
    text-decoration: none;
    color: #233f78;
    transition: margin-left 0.6s, color 0.4s;
    cursor: pointer;
}
.states_dropdown_content .state_link:hover, .state_link:focus {
    margin-left: 10px;
    color: #f37421 !important;
}

.autocomplete_results {
    position: absolute;
    top:40px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 7;
    font-size: 11px;
    background:#ffff;
    box-shadow: 0px 4px 10px lightgrey; 
    border: 1px solid lightgrey;
}
.no-suggestions {
    color: #44a1a0;
    padding: 0.5rem;
}
.suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 2px;
    margin-bottom: 0px !important;
}
.suggestions li {
    padding: 0.5rem;
}
.suggestion-active, .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #fff;
}

.dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }
  
  @media only screen and (max-width: 600px) {
    .mobile-col{
        /* padding-left: 6% !important; */
    }
  }
/* .counter_div_margin{
    margin-top: 16%;
} */
.slider-star img{
    width: 37px;
    height: 37px;
    position:absolute;
    top :-2%;
    left: -5%;
}
.counter_main_div_mobile{
    display: none !important;
}
.owl-dots{
    display: none;
}
.slider-main-div{
    /* box-shadow: 0px -1px 21px -8px rgba(0, 0, 0, 0.25) !important; */
  
}
.slider-desc{
    
    padding: 5%;
    height: 100px;
    box-shadow: 0px 2px 6px lightgrey;
}
.review_desc{
    font-size: 13px !important;
}

@media screen and (min-width:378px) and (max-width: 600px)  {
    .bordertopradius{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }
    .counter_main_div_desktop{
        display: none;
    }
    .counter_main_div_mobile{
        display: flex !important;
    }
    .counter_div_margin{
        margin-top: 3% !important;
        
    }
    .count_number{
        font-size: 12px !important;
    }
    .counter-text{
        font-size: 10px  !important;
    }
}
@media (max-width: 1366px) {
   
    .counter_div_margin{
        margin-top: 5% !important;
        
    }
    .count_number{
        font-size: 20px  !important;
    }
}
@media  (min-width:1366px) and (max-width: 1600px) {
    .counter_div_margin{
        margin-top: 5%  !important;
    }
    .count_number{
        font-size: 20px  !important;
    }
}
@media  (max-width: 1900px) {
    .counter_div_margin{
        margin-top: 8% !important;
    }
    .count_number{
        font-size: 20px !important;
    }
}

@media  (min-width: 1900px) {
    .counter_div_margin{
        margin-top: 15% !important;
    }
    .count_number{
        font-size: 20px !important;
    }
}


.bg-dark-blue {
    background-color: #1a2a56; /* Adjust to match your specific dark blue */
  }
  .home_logo{
    float:right;
        width: 186px ;
        height:186px ;
      
  }
  .text-highlight {
    color: #ff6600;
  }
  
  .offer-card {
    position: relative;
    border: 1px solid #ddd;
  }
  
  .ribbon {
    position: absolute;
    top: 0;
    right: 0;
    background: #c40000;
    color: #fff;
    padding: 5px 10px;
    font-size: 0.8rem;
    transform: rotate(45deg);
    transform-origin: top right;
  }
  
  .price-text {
    font-size: 1rem;
    color: #333;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
    color: #ff6600;
  }
  img {
    border-radius: 0%;
  }
  .list-unstyled li {
    background-image: url(/static/media/predictor_list.55a2c3bf.png);
    background-repeat: no-repeat;
    background-position: 0 11px;
    background-size: 30px 30px;
    padding-left: 45px;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold !important;
    padding-top: -6px;
}
.circle-step {
    background-color: #f2762e;
    color: white;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    width: 80px; /* Increase the circle width */
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .flowchart-text {
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .flowchart-arrow {
    color: #f2762e;
    width: 100px;
  height: auto;
  }
  
  .container {
    background-color: #fef1ec;
    border-radius: 10px;
    padding: 30px;
  }
  
.flowchart-arrow-down {
    width: 50px;
    height: auto;
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
  }
  .home_start_desktop_button{
    display: inherit;
  }
  .home_start_mobile_button{
    display: none;
  }
  .home_image_banner{
    object-fit: contain;
  }
  .tutorial_header{
    text-align: left;
    width: 60% !important;
    margin-left: 18%;
    padding-left: 2%;
    font-size:30px;
    padding-top: 2%;

    /* padding-left: 5%; */
    /* font-size: 15px !important;
    margin: 0 auto; */
}

.tutorial_header_second_one{
    width: 55% !important;
    /* margin: 0 auto; */
    margin-left: 24%;
    padding-left: 4%;
    font-size:25px;
    padding-top: 2%;
    
}
.predictor_feature_button{
    width: 25%;
}
.predictor_feature{

    margin-left: 15%;
    margin-top: 5%;
}
.predictor_features_heading{
    font-weight: bold !important;
  }

  

.iframe{
    margin-top:8%;
    border-radius:20px;
    height:400px;
}
  
  
.rank-link
{
    position:absolute;
    right: 30%;
    bottom: 10%;
}
.properties_font{
    font-size: 0.9rem;
}
.predictor_blue_color{
    color:#233974;
}
.predictor_bg_blue_color{
    background-color: #233974;
}
/* todo: delete file if not needed */
.home_bg_img {
    height: 100vh !important;
    width: 100%;
}
.explore_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
    background-color: #ffe5d6;
}
.border-radius
{
    border-radius: 1.2rem !important;
}
.padding-for-top
{
    padding: 10%;
}
.home_banner_content {
    margin: auto;
    z-index: 6;
}
h2{
    font-family: 'Poppins', sans-serif !important;
}
.our-services-desktop h3 p div
{
    font-family: 'Poppins', sans-serif !important;
    
}
.our-services-desktop  p
{
    font-size: 18px;
    
}
.home_banner_content .search_header {
    color: #44a1a0 !important;
}

.home_banner_content h6 {
    color: #233f78 !important;
    font-weight: 400;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
}
.home_search_group .form-control,
.home_search_group .form-control:focus {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 10% !important;
    margin: 0px !important;
    border-radius: 40px !important;
    font-size: 20px !important;
    color: #44a1a0 !important;
    height: 55px;
    border: 1.5px solid lightgrey;
    box-shadow: 0px 2px 6px lightgrey;
    transition: box-shadow 1s;
}
.home_search_group .form-control:hover,
.home_search_group .form-control:focus {
    box-shadow: 0px 4px 16px lightgrey;
}
.home_search_group .input-group > .custom-select:not(:last-child),
.home_search_group .input-group > .form-control:not(:last-child) {
    border-radius: 40px !important;
}
.home_search_group .input-group-text {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1 !important;
    font-size: 4vh;
    padding-left: 3vh;
    padding-right: 3vh;
    background: #172a3a;
    color: #fff;
    border: 1px solid #ffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    cursor: pointer;
    transition: background 1s;
}
.home_search_group .input-group-text:hover {
    background: #44a1a0 !important;
}
.home_search_group .form-control::placeholder {
    color: lightgrey !important;
}
.home_state_select {
    min-width: 200px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid lightgrey;
    color: #233f78;
    box-shadow: 0px 2px 6px lightgrey;
}
.home_service_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.home_service_container .header {
    color: #ffff;
}
.home_service_container .card {
    background-color: #ffff;
    /* border:1px solid lightgrey; */
    color: #172a3a;
    /* box-shadow: 2px 4px 10px lightgrey; */
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none;
    transition: margin-top 1s, background-color 1s, color 1s, box-shadow 1s;
}
.home_service_container .card:hover {
    color: #44a1a0;
    margin-top: -8px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.6);
}
.home_service_container .card p {
    font-size: 13px;
    color: #233f78;
}
.home_service_container .card span {
    margin-top: 10px;
    font-size: 24px;
}
.down_arrow {
    position: absolute;
    border: 1px solid #ffff;
    bottom: 20px;
    left: 47.7%;
    border-radius: 50%;
    background: black;
    padding: 10px 12px;
    z-index: 5;
    animation: flash 5s infinite;
}

.home_search_group .autocomplete_results {
    position: absolute;
    top: 58px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 11 !important;
    margin: auto;
    text-align: left;
    font-size: 13px;
    background: white;
    border: 1px solid darkgrey;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.6); */
}
.home_search_group .no-suggestions {
    color: #172a3a;
    padding: 0.5rem;
}
.home_search_group .suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 0px !important;
}
.home_search_group .suggestions li {
    padding: 0.5rem;
}
.home_search_group .suggestion-active,
.home_search_group .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.home_search_group .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid whitesmoke;
}
.mci_fmge_group {
    position: absolute;
    top: 10px;
    left: 10px;
}
.mci_fmge_group .fmge_div,
.mci_fmge_group .mci_div {
    font-size: 14px;
    font-weight: 600;
}
#popular_colleges_section {
    background-color: whitesmoke;
}
.promo_video {
    z-index: 20 !important;
}
.exam_dates_table th {
    background-color: #233f78;
    color: #ffff;
    font-size: 24;
    font-weight: 700;
    text-align: center;
    vertical-align: middle !important;
}
.exam_dates_table tr {
    color: #233f78;
    font-size: 24;
    font-weight: 500;
    text-align: center;
}
.exam_dates_table td {
    vertical-align: middle !important;
}
.cutoffScoreText {
    color: #f37421;
    font-size: 16px;
    font-weight: 800;
}

.college_searc_btn
{
    padding: 0.75rem 2.14rem !important ;
    border-radius:20px !important;
    position:relative !important;
    left:-125px !important;
    background:#f47523 !important;
    color:white !important;
}


.our-services-mobile
{
display:none !important;
}
.our-services-desktop
{
    display: flex;
}

.news-btn
{
    z-index: 999999999 !important;
}
.card {
    font-weight: 400;
    border: 0;
    /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
    /* border: 1px solid #f47523 !important; */
}
.predictor_why_our_tool_desktop{
    display: flex;
}
.predictor_why_our_tool_mobile{
    display: none !important;
}
.predictor_benfits_head_desktop{
    display: block;
  }
  .predictor_benfits_head_mobile{
    display: none;
    /* font-weight: bold; */
  }
  .feature_doctor_mobile{
    display: none;
  }
  .feature_list_img{
    text-align: right;
}
.feature_list_text{
    padding-left: 2%;
}
.pricing_heading{
    /* font-size: 30px !important; */
   margin-left: 8%;
}
.pricing_sub_heading{
    /* font-size: 20px !important; */
    margin-top: 4%;
    margin-left: 8% !important;
    font-size: 45px;
}
.list-unstyled{
    margin-left: 8%;
}
.limited_opporturnities{
    padding:5% 0 ;
}
.pricing_button{
    width: 25%;
}
.faq_question{
    /* font-size: 20px; */
    margin-top: 2%;
}
.faq_description{
    font-size:20px !important;
    
}
.faq-padding{
    padding: 4%;
}
.why_out_tool_desc{
    margin: 0 10% !important;
}
.features_margin_right{
    padding-right: 5% !important;
}
@media (max-width: 768px) {
    .features_margin_right{
        padding-right: 0%;
    }
    .flowchart-arrow {
      display: none;
    }
  }
  .fillout_button{
    width: 25%;
    padding: 1%;
  }
  .predictor_steps_div{
    display: none !important;
}
.home-text{
    padding: 0 5%;
}
.home-text-desc{
    padding: 0 5%;
}
.doubts_fill_out_header{
    font-size:40px;
}
.doubts-description{
    margin: 0 10%;
}
.count_number{
    font-size: 30px ;
}
.counter-div{
    margin-top: 5%;
    padding: 0% 10%;
}

  @media (max-width: 500px) {
    .predictor_steps_div{
        display: flex !important;
    }
    .features_top{
        display: none;
    }
    .predictor_steps_desktop{
        display: none !important;
    }
    .fillout_button{
        width: 100%;
        padding: 1%;
    }
    .doubts_fill_out_header{
        font-size: 20px;
    }
    .faq_header{
        font-size: 25px !important;
    }
    .doubts-header{
        font-size: 25px !important;
    }
    .doubts-description{
        font-size: 12px !important;
        margin:0 0 ;
    }
    .faq-padding{
        padding: 5%;
    }
    .faq_question{
        font-size: 14px;

    }
    .faq_description{
        font-size:10px !important;
    }
    .pricing_button{
        width: 75% !important;
        border-radius: 15px !important;
    }
    .limited_opporturnities{
        padding:5% 0 ;
    }
    .list-unstyled{
        margin-left: 0;
        padding:0 3%;
    }
    .list-unstyled li {
        background-image: url(/static/media/predictor_list.55a2c3bf.png);
        background-repeat: no-repeat;
        background-position: 22px 4px !important;
        background-size: 11px 11px !important;
        padding-left: 45px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold !important;
        padding-top: -6px;
    }
    .pricing_heading{
        font-size: 30px !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .pricing_sub_heading{
        font-size: 20px !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .feature_list_img{
        text-align: center;
    }
    .feature_list_text{
        padding-left: 0;
    }
    .feature_doctor_mobile{
        display: flex;
    }
    .feature_doctor_desktop{
        display: none;
    }
  .predictor_benfits_head_desktop{
    display: none;
  }
  .predictor_benfits_head_mobile{
    display: block;
    font-weight: bold;
  }
      .home_logo_div{
        text-align: center !important;
      }
    .why_out_tool_desc{
        font-size: 12px !important;
        text-align: center;
    }
    .why_out_tool_header{
        font-size: 30px !important;
        text-align: center;
       
    }
    .tutorial_header{
        text-align: left !important;
        width: 60% !important;
        font-size: 13px !important;
        margin: 0 auto !important;
        padding-left: 1%;

    }
    .tutorial_header_second_one{
        
        width: 50% !important;
        font-size: 13px;
        margin-left: 21.5%;
        /* margin: 0 auto !important; */
        padding-left: 1%;
        margin-top: 2%;
    
    }
    .arrowtangled{
   
            height: 101px;
            margin-top: 8% !important;
            object-fit: contain;
            margin-left: 12%;
            transform: rotate(29deg) !important;
        }
        
    
    .iframe{
        margin-top:10% ;
        border-radius:20px;
        height: 200px;
    }
    .home_logo{
        float:none;
        width: 100px !important;
        height:100px !important;
      }
     .home-text{
        font-size: 2.5rem !important;
        text-align: center;
     } 
     .home_start_desktop_button{
        display: none;
     }
     .home-text-desc{
        text-align: center;
        font-size: 15px !important;
     }
     .home_start_mobile_button{
        display: block;
      }
      .home_image_banner{
        height: 350px;
      }
      .iframe{
        /* margin-top: 10%; */
      }
      .predictor_features_heading{
        font-size: 17px;
        font-weight: bold !important;
      }
      .predictor_features_desc{
        font-size: 14px;

      }
      .predictor_feature_button{
        width: 75% !important;
    }
   
    .predictor_feature{

        text-align: center !important;
        font-size: 30px !important;
        margin-bottom: 10%;
        margin-top: 5% !important;
        margin-left:0 !important;
    }
    .predictor_why_our_tool_desktop{
        display: none !important;
    }
    .predictor_why_our_tool_mobile{
        display: flex !important;
    }
   
  }


@media only screen and (max-width: 500px) 
    {
.how_it_works{
    font-size: 30px !important;
}
.features_secon_one{
    width: 50%;
}
        .slider-content {
            position: absolute;
            top: 0%;
            left: 5%;
        }
        .card-main {
            background: #f47523 !important;
            position: relative !important;
            top: 8px !important;
            height: 410px;
        }
        .slider-icon{
            font-size: 25px;
            position: relative;
            left: 15% !important;
            
        }
        .sliderheading
        {
            position: relative;
            top: -25px !important;
            font-size: 14px;
        }
        .slidercontent
        {
            position: relative;
            top:-23px !important;
            font-size: 12px;
        }
        .slider-button
        {
            position: absolute;
            top: 40%;
            left: -24px !important;
        
        }
        /* .sliderheading
        {
            position: relative;
            top: -20px !important;
        }
        

       .slider-button
       {
        position: relative;
        top:-40px;
       } */

    .padding-for-top
{
    padding: 0px !important;

}
.text-table
{
    font-size: 0.94rem !important;
}

}
.card-main
{
    background:#f47523 !important;
    position: relative;
    top:-50%;
    height: 410px;
    
}


.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -50px !important;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators li {

    width: 22px;
    height: 3px;
    margin-right: 10px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: orange !important;
    background-clip: padding-box;
    border-top: 0px solid transparent;
    border-bottom: 5px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    
}  
.bg-orange
{
    background-color: #f47523 !important;
} 
#demo
{
    position: absolute;
    top:12%;
    width: 98%;

}
#demo-mobile
{
    display: none;
}
.testimonial_img
{
    width: 100px !important;
    height: 100px !important;
}
.sub-card
{
    position: relative;
    top: -30%;
    z-index: 2;
   
}
.card-height
{
    height:280px;
}
.slider-content
{
    position:absolute;
    top:30%;
    left:5%;
}
.more-btn {
    display: inline-block !important;
    background: rgba(255, 255, 255, 0.2) !important;
    padding: 6px 30px 8px 30px !important;
    color: #fff !important;
    border-radius: 50px !important;
    transition: all ease-in-out 0.4s !important;
}
.orange-color
{
    color:#f47523 !important;
}
.college_search_input{
    width:75%;
    margin-top:5% !important;
}

@media screen and (max-width: 768px) {


    .info-mobile
{
    padding-left: 15% !important;
}
    .our-services-desktop
    {
        display: none !important;
    }

.our-services-mobile
{
display:block !important;
}
    .college_search_input{
        width:60%;
        margin-top:1% !important;
        
    }
.college_searc_btn
{
    padding: 0.75rem 2.14rem;
    border-radius:20px;
    position:relative;
    left:-56px !important;
    background:#f47523 !important;
    color:white;
}
    .sub-card {
        position: relative;
        top: 3%;
        z-index: 2;
    }
    #demo-mobile
    {
        display: block;
        /* position: relative;
        top: -143px; */
    }
    #demo
    {
        display: none;
    }
    .home_banner_content h1 {
        font-size: 24px;
    }
    .home_banner_content h4 {
        font-size: 16px;
    }
    .home_search_group {
        margin-top: 20px !important;
    }
    .home_search_group .input-group-text {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .home_service_container {
        margin-top: 20px !important;
    }
}

.about_us_container {
    padding-top: 70px;
}

.about_us_container .header {
    color: #233f78;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 14px;
}
.about_us_container .para {
    font-size: 14px;
    color: #172a3a;
    text-align: justify;
}
.description_card {
    padding: 3vh 12vh;
}
.description_card div {
    padding: 20px 30px;
}
.description_card .service_header {
    text-align: center;
    color: #f37421;
    text-transform: uppercase;
}
.about_us_container .description_card:nth-child(odd) {
    background: white;
}
.about_us_container .description_card:nth-child(even) {
    background: whitesmoke;
}

@media screen and (max-width: 768px) {
    .description_card {
        padding: 20px !important;
    }
    .description_card div {
        padding: 10px 15px !important;
    }
}

.pricing_container {
    margin: 30px auto;
}

.animate-pulse
{
    animation:pulse 4s infinite;
}
.pricing_container .header {
    color: #233f78;
}
.pricing_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.pricing_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.pricing_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.pricing_container .popular_banner {
    position: absolute;
    top: -30px;
    right: -6px;
    z-index: 2;
    height: 66px;
}
.pricing_container .offer_banner {
    height: 50px;
}
.pricing_container .benefit_true i {
    color: #2ec4b6;
}
.pricing_container .benefit_false i {
    color: #bd2426;
}
.pricing_container .benefit_false {
    text-decoration: line-through;
}
.pricing_container .abroad_btn {
    box-shadow: none;
    width: 50%;
    padding: 10px;
}
.pricing_container .actual_price {
    text-decoration: line-through;
    color: #ffff;
    font-size:20px;
}

.checkout_container {
    margin: 30px auto;
}
.checkout_container .header {
    color: #233f78;
}
.checkout_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.checkout_container .checkout_package {
    color: #233f78;
}
.checkout_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.checkout_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.checkout_container .check_icon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    height: 77px;
    background: white;
    border-radius: 50%;
    padding: 8px;
}
.checkout_container .benefit_true i {
    color: #2ec4b6;
}
.checkout_container .benefit_false i {
    color: #bd2426;
}
.checkout_container .benefit_false {
    text-decoration: line-through;
}
.checkout_container .abroad_btn {
    box-shadow: none;
    width: 100%;
    padding: 10px;
}
.checkout_container .cancel_btn {
    background: #44a1a0;
}
.checkout_container .header {
    color: #2ec4b6;
}
.checkout_container .actual_price {
    color: #ffff;
}
.checkout_container .offer_banner {
    height: 50px;
}
.remove_cart_btn {
    position: absolute;
    top: -15px;
    right: -15px;
    height: 40px;
    width: 40px;
    color: #bd2426;
    border-radius: 20px;
    z-index: 2;
    background: #ffff;
    padding: 10px;
    box-shadow: 0px 0px 10px lightgray;
}
.checkout_container .coupon_btn {
    height: 38px !important;
    padding: 8px 20px !important;
}

/* .background_image_predictor_side{
  background-image: url('../../assets/predictor_background_image_side.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
 
} */

.vertical-line {
  position: absolute;
  left: 4%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #dee2e6;
  z-index: 0;
}

.step-icon {
  width: 24px;
  height: 24px;
  z-index: 1;
}

.check-icon-wrapper {
  background-color:#28a745;
  border: 1px solid white; /* Bootstrap green */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.predictor_course_level_switch{
  width:111px !important;
  border-radius:20px;
  margin:0 auto;
  
}
.predictor_page{
  /* height: 100vh !important; Ensure full height */
  overflow-y: auto !important; /* Enable vertical scrolling */
  overflow-x: hidden !important;
}
.predictor-container {
    height: 100vh;
    background-color: #fae9e5; /* Light pink background as in the image */
  }
  .border_radius{
    border-radius: 10px !important;
    border:1px solid black !important;
  }
  .predictor-card {
    border-radius: 20px !important;
    padding: 30px 0px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-right:10% ;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #233974; /* Dark blue color for the header */
  }
  
  input, select {
    font-size: 14px;
  }
  
  img {
    max-width: 100%;
    border-radius: 15px;
  }
  .predictor_submit_button{
    width:50% !important;
  }
  .predictor_lable{
    color:#1d3d79;
    font-size:13px;
  
  }
  .predictor_heading{
    margin-bottom: 7% !important;
  }
  .shadow{
    box-shadow: 0px -1px 21px -8px rgba(0, 0, 0, 0.25) !important;

  }
  .predictor-benfits-display{
    padding-left: 5% !important;
  }
  @media screen and (min-width: 1366px) and (max-width:1600px)  {
    .vertical-line {
      left: 5.5% !important;
      bottom: 3% !important;
    }
  }
  @media only screen and (max-width: 580px) {
    .vertical-line {
      left: 10%;
      bottom: 10%;
    }
    .predictor-card{
      padding:0px;
      margin-right:0% !important ;
    
    
    }
    .predictor_second_div{
      margin-bottom:20%;
      padding: 0% 10% !important;
    }
    .predictor-benfits-display{
      padding:0 7% !important;
    }
    .predictor_doctor_image_position{
      display: none !important;
      
    }
    .predictor_submit_button{
      width:60% !important;
    }
    
    .predictor_heading{
      font-size: 20px;
      font-weight: bold !important;
      margin-top :5%;
    }
  }
.background_image_predictor_side{
    background-image: url(/static/media/predictor_background_image_side.036f45f1.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
   
    /* height:400px; */
    /* opacity: 0.2; */
    /* transform: rotate(-25deg) !important; */
}
.login-header{
    font-size: 50px !important;
    font-weight: bold;
}
.predictor_cards{
    padding: 2% 0;
}
.login-content-img{
    width: 10%;
    padding-top: 2%;
    text-align: center;
}
.login-content-desc {
    width: 90%;
    text-align: left;
    font-size: larger;
    padding-top: 2%;
    padding-right:2% ;
    font-weight: bold;
}
  
.register_container {
    /* background: linear-gradient(to bottom right, #44a1a0, #172a3a) !important; */
   /* background: #233974; */
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.register_container h3 {
    color: #ffff;
    font-weight: 600;
}
.register_container [type="radio"] {
    opacity: 0;
    height: 0px;
}
.register_container [type="radio"] + img {
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.6;
    box-shadow: none;
    border: 5px solid transparent;
    transition: box-shadow 1s, border 1s, opacity 1s;
}
.register_container [type="radio"]:checked + img {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
    border: 5px solid whitesmoke;
    opacity: 1;
}
.register_container .terms_check a {
    color: whitesmoke;
}
.register_container .terms_check a:hover {
    color: #2ec4b6;
}
.register_details {
    color: #ffff;
}
.register_container .form-control,
.register_container .form-control:focus {
    background: transparent;
    /* color: white; */
    outline: none;
    border: none;
    /* border: 0.1px solid whitesmoke; */
    border:1.5px solid #000000b3 !important;
    border-radius: 10px ;
    /* box-shadow: none; */
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
    /* border-radius: 0px; */
}
/* .register_container .form-control::placeholder {
    color: whitesmoke;
} */
.register_container .input-group-text {
    background: #172a3a;
    width: 40px;
    justify-content: center;
    color: #ffff;
    border-radius: 0px;
}
.register_button{
    width: 50%;
}
.register_heading_text{
    font-size: 3.5rem;
}
 .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none !important;
}
@media (max-width: 600px) {
.register_gender{
    /* display: flex; */
    /* margin-left:2%; */
}
.predictor_register_form_div{
    padding: 0 10% !important;
}


.register_button{
    width: 100%;
}
.second_input{
    margin-top: 15px;
}
.register_heading_text{
    font-size: 2rem;
}
}
#contact_container .contact_form {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    border: 1px solid whitesmoke;
}
#contact_container div.contact {
    color: #ffff;
    font-weight: 800;
}
#contact_container .bg_overlay {
    opacity: 0.4;
    background: #172a3a;
}
#contact_container hr {
    background: white;
}
#contact_container .social {
    padding: 0px;
}
#contact_container .social li {
    display: inline;
    margin: auto 10px;
}
#contact_container .social a {
    font-size: 24px;
    color: #233f78;
    transition: color 1s;
}
#contact_container .social a:hover {
    color: #2ec4b6;
}
#contact_container .main_header {
    color: #f37421;
}
#contact_container .header {
    color: #233f78;
}
#contact_container img {
    height: 60px;
    margin-bottom: 10px;
}
#contact_container .sub_header {
    color: #44a1a0;
    font-size: 18px;
    font-weight: 600;
}
#contact_container p.para {
    font-size: 14px;
    color: #233f78;
}
#contact_container .branch_div {
    padding: 20px;
}
#branch_offices p.para {
    font-size: 13px;
}

/* todo: delete file if not needed */
.interested_side_container {
    background: linear-gradient(to bottom, #44a1a0, #172a3a);
    padding: 20px;
    color: #ffff;
}
.interested_side_container .form-control {
    color: #172a3a;
}
.interested_side_container .abroad_btn {
    border: 2px dotted #ffff;
}

.state_card img {
    height: 333px;
    width: 100%;
}
.col_details h3,
.col_details span {
    color: #ffff;
}

.filter_results .college_card {
    border: 1px solid lightgrey;
    border-radius: 0px;
    text-align: left;
    box-shadow: 0px 2px 4px lightgrey;
    margin-bottom: 15px;
    transition: box-shadow 1s;
}
.filter_results .college_card:hover {
    box-shadow: 0px 4px 10px 2px lightgrey;
}
.filter_results .college_card h5 {
    color: #233f78;
    margin-bottom: 2px;
}
.filter_results .college_card .small_text {
    margin: 2px;
    color: #233f78 !important;
}
.filter_results .college_card img {
    height: 100px;
    width: 100px;
}
.filter_results .college_card .accr_name {
    font-size: 12px;
}
.filter_results .fav_icon {
    padding: 0px;
    top: 0px;
    right: 0px;
    background: none;
    font-size: 24px !important;
}
.filter_results .abroad_btn {
    border-radius: 0px;
    font-size: 13px;
    padding: 3px 6px;
    box-shadow: none;
    border: 1px solid black;
}
.filter_results .course_container {
    margin-top: 10px;
    border-top: 1px solid lightgray;
    padding: 10px;
    color: #233f78;
}
.filter_results .course_container p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
.filter_results .course_container p i {
    margin: 3px 5px 0px 0px;
}

/* todo: delete file if not needed */
.college_container .banner_div {
    height: 33vh;
    padding: 0px;
}
.college_container .bg_overlay {
    opacity: 0.8;
}
.college_container img.col_banner {
    width: 100%;
    height: 100%;
}
.college_container img.col_logo {
    height: 150px;
    width: 150px;
}
.college_details {
    position: absolute;
    top: 3vh;
    right: 2vh;
    left: 2vh;
    z-index: 5;
    /* background: rgba(0,0,0,0.6); */
    color: #ffff;
    font-weight: bolder;
}
#college_navbar {
    position: sticky;
    top: 62px;
    right: 0px;
    left: 0px;
    background: #ffff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index: 5;
    box-shadow: 0px 2px 4px lightgrey;
}
#college_navbar .navbar_btn {
    position: static;
}
#college_details_navbar span.nav-link {
    color: #233f78;
    cursor: pointer;
    padding: 10px auto;
    border-bottom: 2px solid transparent;
}
#college_details_navbar span.nav-link:hover,
#college_details_navbar span.nav-link.active {
    color: #f37421 !important;
    font-weight: bolder !important;
}
.college_details_card {
    box-shadow: 0px 0px 10px lightgray;
    margin: 2px -6px;
}
.college_details_card .details_div {
    border-bottom: 1px solid lightgrey;
    padding: 10px 20px;
}
.college_details_card .details_div h5 {
    font-weight: 500;
    padding: 10px 0px 0px;
    color: #f37421;
    text-align: left;
}
.college_details_card .hori_line {
    margin-left: 0px;
    width: 60px;
}
.college_details_card .details_div p {
    color: rgba(0, 0, 0, 0.7);
    text-align: justify;
    font-size: 14px;
}
.college_container .fav_icon {
    font-size: 26px !important;
    top: 0px;
    right: 20px;
}
#courses_div p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
#courses_div p i {
    margin: 3px 5px 0px 0px;
}
#courses_div a,
#courses_div h6 {
    color: #233f78;
}
#courses_div .course_details:nth-child(even) {
    background: white;
}
#courses_div .course_details:nth-child(odd) {
    background: whitesmoke;
}
#courses_div .abroad_btn {
    font-size: 13px;
    width: 100%;
    box-shadow: none;
}
#courses_div .table th {
    color: #172a3a !important;
    font-weight: 600;
}
#courses_div .table {
    color: #233f78 !important;
}
#facilities_div img {
    height: 55px;
    width: 55px;
}
#facilities_div h6 {
    margin-top: 10px;
    color: #233f78;
    font-weight: 500;
    text-align: center;
}
#contact_div p {
    color: #233f78;
}
#gallery_div img {
    height: 500px;
    width: 100%;
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
    object-fit: contain !important;
}
.image-gallery-content,
.image-gallery-slide {
    background: rgba(0, 0, 0, 0.9) !important;
}
.image-gallery-thumbnail {
    opacity: 0.6 !important;
    width: 20% !important;
}
.image-gallery-thumbnail.active {
    background: none !important;
    opacity: 1 !important;
}
.image-gallery-image {
    height: 100% !important;
    object-fit: cover !important;
}
.image-gallery-thumbnail-inner,
.image-gallery-thumbnail-inner img {
    height: 150px !important;
    object-fit: cover !important;
}

@media screen and (max-width: 768px) {
    .college_container img.col_logo {
        height: 66px;
        width: 66px;
    }
    .college_container .banner_div {
        height: 50vh;
    }
    .college_container .fav_icon {
        top: -60px;
    }
    .college_container h2 {
        font-size: 22px;
        margin-top: 10px;
        text-align: center;
    }
    .college_details {
        /* display: none !important; */
    }
    #gallery_div img {
        height: 350px;
    }
    .image-gallery-thumbnail-inner,
    .image-gallery-thumbnail-inner img {
        height: 100px !important;
    }
}

.state_card img {
    height: 333px;
    width: 100%;
}
.col_details h3,
.col_details span {
    color: #ffff;
}

/* todo: delete file if not needed */

/* todo: delete file if not needed */

.compare_container {
    margin-top: 40px;
    min-height: 40vh;
}
.compare_container .autocomplete_results {
    background: #ffff;
    border: 1px solid lightgray;
    position: absolute;
    top: 45px;
    box-shadow: none;
    box-shadow: 0px 0px 6px lightgray;
}
.compare_container input[type="search"].form-control {
    color: #233f78;
    padding-right: 40px;
    transition: box-shadow 0.5s;
    height: 44px;
}
.compare_container input[type="search"].form-control:focus {
    border: 1px solid lightgray;
    outline: none;
    box-shadow: 0px 2px 6px lightgray;
}
.compare_container .input-group-text {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 12px;
    background: transparent;
}

.compare_container .sub_header {
    color: #ffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
}
.compare_container p {
    color: #233f78;
    font-size: 15px;
    margin: 4px;
}
.compare_container .compare_cat ul {
    list-style-type: none;
    padding: 4px 10px;
}
.compare_container .course_header {
    padding: 8px 4px;
    color: #f37421;
    font-weight: 600;
}
.compare_cat ul > li {
    line-height: 2;
}
.compare_cat ul > li .course_check {
    margin-right: 6px;
    margin-top: 2px;
}
.compare_container .small_text {
    color: #172a3a;
    text-decoration: underline;
}
.compare_container .compare_cat {
    padding: 4px;
    color: #233f78;
    border: 1px solid lightgrey;
}
.compare_container .compare_cat i {
    font-size: 18px;
}
.compare_container .college_header {
    position: sticky;
    top: 66px;
    z-index: 10;
    padding: 10px;
    min-height: 80px;
    box-shadow: 0px 0px 4px black;
    background: #44a1a0;
    border: 1px solid white;
}
.compare_container .compare_cat a {
    color: #ffff;
}
.compare_container .course_check {
    cursor: pointer;
    margin-top: 14px !important;
}
.compare_container .course_check i.fa {
    font-size: 15px;
}
.compare_container .course_check[aria-expanded="true"] i.fa-plus {
    display: none;
}
.compare_container .course_check[aria-expanded="false"] i.fa-minus {
    display: none;
}
.compare_container .fav_icon {
    position: absolute;
    top: -20px;
    right: -14px;
    font-size: 24px !important;
    background: #ffff;
    padding: 6px;
    box-shadow: 0px 0px 10px lightgrey;
}

.edit_profile_container {
    margin: 30px auto;
}
.edit_profile_container .header {
    color: #233f78;
}
.edit_profile_container .sub_header {
    color: #f37421;
    font-size: 24px;
    font-weight: 500;
}
.edit_profile_container .para {
    color: #233f78;
}
.edit_profile_container [type="radio"] {
    opacity: 0;
    height: 0px;
}
.edit_profile_container [type="radio"] + img {
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.6;
    box-shadow: none;
    border: 5px solid transparent;
    transition: box-shadow 1s, border 1s, opacity 1s;
}
.edit_profile_container [type="radio"]:checked + img {
    box-shadow: 0px 0px 10px 4px lightgrey;
    border: 5px solid white;
    opacity: 1;
}
.edit_profile_container .change_password,
.edit_profile_container .edit_profile {
    box-shadow: 0px 4px 10px lightgray;
    padding: 20px;
}
.edit_profile_container .form-control {
    color: #233f78;
}
.edit_profile_container .small_text {
    color: #172a3a;
    float: left;
}
.edit_profile_container .abroad_btn {
    padding: 8px 20px;
}
.change_password .show_hide_pwd {
    font-size: 30px;
    color: #233f78;
    padding: 4px 10px;
    /* color: #ffff; */
}

/* todo: delete file if not needed */

/* todo: delete file if not needed */

.course_container {
    padding-top: 70px;
}

.course_container .header {
    color: #233f78;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 14px;
}
.course_container .para {
    font-size: 14px;
    color: #172a3a;
    text-align: justify;
}
.description_card {
    padding: 3vh 12vh;
}
.description_card div {
    padding: 20px 30px;
}
.description_card .service_header {
    text-align: center;
    color: #f37421;
    text-transform: uppercase;
}
.course_container .description_card:nth-child(odd) {
    background: white;
}
.course_container .description_card:nth-child(even) {
    background: whitesmoke;
}

@media screen and (max-width: 768px) {
    .description_card {
        padding: 20px !important;
    }
    .description_card div {
        padding: 10px 15px !important;
    }
}

.rank_predictor_title
{
    font-size: 2rem;
}
.rank_main_div
{
    margin-top:10%;
    margin-bottom: 5%;
}
.animate_image
{
    animation:pulse 2s infinite; /* referring directly to the animation's @keyframe declaration */
    
}
.animate_button
{
    animation:jello 2s infinite;
}
.padding-inside
{
    padding:2% 0% !important;
}
@media (max-width: 768px) {
    .rank_predictor_title
    {
        font-size: 1rem;
        margin-top: 20%;
    }  
}
.desktop-predictor-bar{
  display: flex ;
}
.mobile-predictor-bar{
  display: none !important;
}
.desktop_header{
  display:block;
}
.predicted_display_header{
  font-size: 1.75rem;
}
.info_header_box {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #f47524 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}
.nav {
  display: flex
;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
}
.mobile-cards{
  display: none;
}
.mobile_header{
  display: none;
}
.predicted_rank_row{
  display: none;
}
.mid_info_display_desktop{
  display:flex;
}
.mid_info_display_mobile{
  display: none;
}
.change_button {
  /* width: 100%; */
  width: 25%;
  margin-top: 8px;
  font-size: 14px;
  padding: 10px;
  background-color: #F47523;
  border-radius: 5px;
  color: white;
  border: none;
}


.predictor_year_switch{
  width: 143px !important;
  border-radius:20px ;
}
@media (max-width:1600px) {
  .predictor_year_switch{
      width: 143.3px !important;
      border-radius:20px ;
  }
}
@media (max-width:1366px) {
  .predictor_year_switch{
      width: 108.3px !important;
      border-radius:20px ;
  }
}
.predictor-disclaimer{
  padding: 0% 5%;
    }
    .predictor_cards_mobile{
      display: none !important;
    }
/* Mobile View (up to 768px width) */
@media only screen and (max-width: 580px) {
  .predictor_cards_mobile{
    display: block !important;
  }
  .predictor_bg_blue_color{
    background-color: white !important;
  }
  .predictor-disclaimer{
margin: 0% 5%;
  }
  .predictor_year_switch{
    width: 143px !important;
    border-radius:20px ;
  }
  .mobile-predictor-bar{
    display: flex !important;
  }
  .desktop-predictor-bar{
    display: none !important;
  }
  .predicted_display_header p{
    font-size: 12px;
  }
  .mid_info_display_desktop{
    display:none !important;
  }
  .mid_info_display_mobile{
    display: block;
  }
  .college_card{
    display: none;
  }
  .mobile-cards{
    display: block;
    padding: 4%;
  }
    /* Main wrapper padding for mobile */
    .predicted_college_main_div {
      padding: 10px;
      background-color: #fce6da;
    }
    .desktop_header{
      display:none;
    }
    /* Stack info header vertically for mobile */
    .info_header_box {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
  
    /* Rank and user info box adjustments */
    .info_header_box h4 {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  
    /* Full width Change button for mobile */
    .change_button {
      /* width: 100%; */
      width: 70%;
      margin-top: 8px;
      font-size: 14px;
      /* padding: 10px; */
      background-color: #F47523;
      border-radius: 5px;
      color: white;
      border: none;
    }
  
    /* Disclaimer box adjustments */
    .alert {
      font-size: 12px;
      padding: 10px;
      margin-bottom: 15px;
      background: #E8F0EE;
      border: 2px solid #E8F0EE;
    }
  
    /* Each predicted college card */
    .college_card {
      background-color: white;
      border-radius: 8px;
      margin-bottom: 15px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      display: none !important;
    }
  
    /* Adjust card layout for mobile */
    .college_card .row {
      flex-direction: column;
      align-items: center;
    }
  
    /* Reduce logo size inside the card */
    .college_card img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
  
    /* College name */
    .college_card .col-md-12 {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    /* College details like rank, fees, etc. */
    .college_card .row div {
      font-size: 12px;
      text-align: center;
      margin-bottom: 5px;
    }
  
    /* Full width for Download button on mobile */
    .btn {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      background-color: #F47523;
      color: white;
      border: none;
      border-radius: 5px;
      text-align: center;
    }
    .mobile_header{
      display: block;
      box-shadow: 1px 2px 10.1px -3px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
  
 
  
.background_image_predictor_side{
    background-image: url(/static/media/predictor_background_image_side.036f45f1.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
   
    /* height:400px; */
    /* opacity: 0.2; */
    /* transform: rotate(-25deg) !important; */
}
.login-header{
    font-size: 50px !important;
    font-weight: bold;
}
.predictor_cards{
    padding: 2% 0;
}
.login-content-img{
    width: 10%;
    padding-top: 2%;
}
.login-content-desc {
    width: 90%;
    text-align: left;
    font-size: larger;
    padding-top: 2%;
    padding-right:2% ;
    font-weight: bold;
}
.logo_predictor{
    width:183px;
    height:181px;
}
.doctors_group{
    width: 836px;
    height:547px;
}
.college_benefits {
    list-style: none; /* Remove default bullets */
    padding-left: 40px; /* Adjust space for the image */
}
.background{
    background:#FFEEE6;
}
.rounded-border{
    border-radius: 15px;
    border:1px solid black;
}
.predictor_cards{
    box-shadow: 0px -1px 21px -8px rgba(0, 0, 0, 0.25);
    

}
.college_benefits li {
    background-image: url(/static/media/predictor_list.55a2c3bf.png);
    background-repeat: no-repeat;
    background-position: 0 1px; /* Adjust the position of the image */
    background-size: 20px 20px; /* Set the size of the image */
    padding-left: 30px; /* Space between the image and the text */
    margin-bottom: 10px; /* Space between list items */
    color:white;
    font-weight: bold;
}
.blue-color{
    color:#233974 !important;
}
.border-color{
    border:1.5px solid #000000b3 !important;
}
.borderradius{
    border-radius: 10px !important;
}
.bg-orange{
    background-color:#233974;
}
.logo_predictor{
    margin-left: 5%;
}
.college_benefits{
    margin-left: 4%;
}
.heading-info{
    margin-left:6%;
}
.logo_predictor_mobile{
    display: none;
}
@media (max-width: 600px) {
    .login-header{
        font-size: 25px !important;
        font-weight: bold;
    }
    .login-content-desc{
        width: 85% !important;
        padding-left: 10px;
        text-align: left;
        font-size: 12px;
        
    }
    .predictor_login_form{
        padding: 0 10% !important;
    }
    .logo_predictor_mobile{
        display: block;
    }
    .college_benefits{
        display: none;
    }
    .doctors_group{
        display: none;
    }
    .logo_predictor{
        display: none;
        
    }
    .college_benefits{
        margin-left: 1%;
    }
    .heading-info{
        /* margin-left:1%; */
        display: none;
    }
    
}
.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h3 {
    color: #233974;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
  
  h2 {
    color: #233974;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 8px;
    text-align: center;
    font-size: 24px;
    border: none;
    border-bottom: 2px solid #f47523;
    background-color: transparent;
    outline: none;
    color: white !important;
    font-weight: bold;
  }
  
  .otp-input:focus {
    border-bottom: 3px solid #f47523;
  }
  
  .otp-resend {
    text-align: center;
    color: #f47523;
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .otp-submit-btn {
    background-color: #f47523;
    color: white;
    border: none;
    padding: 12px 40px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }
  
  .otp-terms {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  
  .otp-terms span {
    color: #233974;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .otp-message {
    color: green;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }
  
.upgrade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f6f8fc;
}
small{
    font-size: 20px !important;
    font-weight: bold !important;
}
.list-unstyled{
    list-style-image:url(/static/media/predictor_background_image_side.036f45f1.png);;
}
.upgrade-content {
    text-align: center;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
}
.predictor_main_payment_page{
    padding: 0 5% !important;
}
.upgrade-content p {
    font-size: 18px;
    color: #012269;
}

.upgrade-content strong {
    font-weight: bold;
}

.offer-box {
    background-color: #ffeae3;
    padding: 30px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.discount {
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin-bottom: 10px;
}
.discount-left{
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin: 10% 0;
}
.limited-offer {
    font-size: 30px;
    color: #7d7d7d;
    margin-bottom: 20px;
}

.price {
    font-size: 42px;
    font-weight: bold;
    color: #012269;
}

.current-price {
    font-size: 42px;
    color: #012269;
}

.original-price {
    text-decoration: line-through;
    font-size: 18px;
    color: #999;
    margin-left: 10px;
}

.tax-info {
    font-size: 14px;
    color: #7d7d7d;
    margin: 10px 0;
}

.upgrade-btn {
    background-color: #f89a27;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.upgrade-btn:hover {
    background-color: #d87f1d;
}
.modal-content{
    border-radius: 15px !important;
}
.payment_background_color{
    background-color: #233974 !important;
}
.header-title{
    color:white !important
}
.border-mobile{
    border-radius: 10px !important;
    padding: 3% 3%;
}
.payment_container_padding{
    background-color: #233974;
}
.text-orange {
    color: #f26522;
  }
  
  .btn-orange {
    background-color: #f26522;
    color: white;
    border-radius: 5px;
    border: none;
  }
  
  .btn-orange:hover {
    background-color: #e0561d;
  }

@media (max-width: 600px) {
    .discount {
        font-size: 50px !important;
        color: #012269;
        margin-bottom: 10px;
    }
    .discount-left{
        font-size: 50px !important;
        font-weight: bold;
        color: #012269;
        margin-bottom: 10%;
    }
    .limited-offer {
        font-size: 30px;
        color: #7d7d7d;
        margin-bottom: 0px;
    }
    .offer-box {
        padding: 20px;
    }
    .container{
        background-color: #233974;
        padding-bottom: 5% !important;
    }
    .border-mobile-top{
        border-top:1px solid darkgray;
    }
    .header-title{
        color:#233974 !important;
        font-size:14px;
        padding: 0 3%;
        justify-content:safe;
    }
    .payment_background_color{
        background-color: #fff !important;
        
    }
    .payment_container_padding{
        padding: 0% 5% !important;
    }
    .border-mobile{
        border: 1px solid #233974;
        margin:0px 5% 0px 5% !important;
        
        /* padding: 5% 5%; */
    }
    .discount {
        font-size: 28px;
    }

    .price {
        font-size: 32px;
    }

    .current-price {
        font-size: 32px;
    }

    .original-price {
        font-size: 16px;
    }
}

.upgrade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f6f8fc;
}

.upgrade-content {
    text-align: center;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
}

.upgrade-content p {
    font-size: 18px;
    color: #012269;
}

.upgrade-content strong {
    font-weight: bold;
}

.offer-box {
    background-color: #ffeae3;
    padding: 30px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.discount {
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin-bottom: 10px;
}
.discount-left{
    font-size: 80px !important;
    font-weight: bold;
    color: #012269;
    margin: 10% 0;
}
.limited-offer {
    font-size: 30px;
    color: #7d7d7d;
    margin-bottom: 20px;
}

.price {
    font-size: 42px;
    font-weight: bold;
    color: #012269;
}

.current-price {
    font-size: 42px;
    color: #012269;
}

.original-price {
    text-decoration: line-through;
    font-size: 18px;
    color: #999;
    margin-left: 10px;
}

.tax-info {
    font-size: 14px;
    color: #7d7d7d;
    margin: 10px 0;
}

.upgrade-btn {
    background-color: #f89a27;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.upgrade-btn:hover {
    background-color: #d87f1d;
}
.modal-content{
    border-radius: 15px !important;
}
.payment_background_color{
    background-color: #233974 !important;
}
.header-title{
    color:white !important
}
.border-mobile{
    border-radius: 10px !important;
    padding: 3% 3%;
}
.payment_container_padding{
    background-color: #233974;
}
@media (max-width: 600px) {
    .discount {
        font-size: 50px !important;
        color: #012269;
        margin-bottom: 10px;
    }
    .discount-left{
        font-size: 50px !important;
        font-weight: bold;
        color: #012269;
        margin-bottom: 10%;
    }
    .limited-offer {
        font-size: 30px;
        color: #7d7d7d;
        margin-bottom: 0px;
    }
    .offer-box {
        padding: 20px;
    }
    .container{
        background-color: #233974;
        padding-bottom: 5% !important;
    }
    .border-mobile-top{
        border-top:1px solid darkgray;
    }
    .header-title{
        color:#233974 !important;
        font-size:14px;
        padding: 0 3%;
        justify-content:safe;
    }
    .payment_background_color{
        background-color: #fff !important;
        
    }
    .payment_container_padding{
        padding: 0% 5% !important;
    }
    .border-mobile{
        border: 1px solid #233974;
        margin:0px 5% 0px 5% !important;
        
        /* padding: 5% 5%; */
    }
    .discount {
        font-size: 28px;
    }

    .price {
        font-size: 32px;
    }

    .current-price {
        font-size: 32px;
    }

    .original-price {
        font-size: 16px;
    }
}

.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h3 {
    color: #233974;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
  
  h2 {
    color: #233974;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 8px;
    text-align: center;
    font-size: 24px;
    border: none;
    border-bottom: 2px solid #f47523;
    background-color: transparent;
    outline: none;
    color: #233974;
    font-weight: bold;
  }
  
  .otp-input:focus {
    border-bottom: 3px solid #f47523;
  }
  
  .otp-resend {
    text-align: center;
    color: #f47523;
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .otp-submit-btn {
    background-color: #f47523;
    color: white;
    border: none;
    padding: 12px 40px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }
  
  .otp-terms {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  
  .otp-terms span {
    color: #233974;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .otp-message {
    color: green;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }
  
.predictor_benefits_desktop_view{
    display: block;
}
.predictor_benefits_mobile_view{
    display: none;
}
.collegebenefits{
    list-style-type: none;
}

.collegebenefits li {
    background-image: url(/static/media/predictor_list.55a2c3bf.png);
    background-repeat: no-repeat;
    background-position: 0 1px; /* Adjust the position of the image */
    background-size: 15px 15px; /* Set the size of the image */
    padding-left: 20px; /* Space between the image and the text */
    margin-bottom: 10px; /* Space between list items */
    font-size: 12px;
    font-weight: bold !important;
}
@media (max-width: 600px) {
    .predictor_benefits_desktop_view{
        display: none !important;
    } 
    .predictor_benefits_mobile_view{
        display: block;
    }
}
.footer_container {
    background-color: #172a3a;
    color: whitesmoke;
    font-size: 13px;
    padding: 50px 30px 10px;
}
.footer_container hr {
    background: grey;
}
.footer_container .connect_with p {
    font-size: 16px;
}
.footer_container ul.branches li,
.footer_container ul.social li {
    list-style: none;
    margin-right: 20px;
    display: inline;
}
.footer_container ul.navigate li {
    list-style: none;
}
.footer_container ul.navigate li a,
.footer_container ul.branches li a {
    color: #ffff;
    transition: color 0.5s, margin-left 0.5s;
}
.footer_container ul.navigate li a:hover,
.footer_container .link_active {
    color: #f37421 !important;
    margin-left: 6px;
}
.footer_container ul.branches li a:hover {
    color: #f37421 !important;
}
.footer_container ul.navigate li i {
    font-size: 10px;
}
.footer_container .copyright span {
    font-size: 14px;
    color: #f37421;
}
.footer_container .social_icon {
    font-size: 26px;
    color: #ffff;
    transition: color 0.4s;
}
.footer_container .social_icon:hover {
    color: #f37421;
}

